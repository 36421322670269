<template>
  <div class="big" style="overflow: hidden">
    <headPage />
    <div>
      <!-- <img
        style="
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          display: block;
          width: 100%;
          z-index: -1;
        "
        src="../assets/image/homepage.jpg"
      /> -->
      <div class="options" @click="jump('/caseSelection')">
        <img
          class="icon1"
          style="display: block; width: 15px"
          src="../assets/icon/return.png"
        />
        <span class="op1">案例大栏目</span>
        <img
          class="icon2"
          style="display: block; width: 15px"
          src="../assets/icon/arrow-right.png"
        />
        <span class="op2">案例分类名</span>
        <img
          class="icon3"
          style="display: block; width: 15px"
          src="../assets/icon/arrow-right.png"
        />
        <span class="op3">{{caseInfo.name}}</span>
      </div>
      <div class="box1">
        <img
          class="img1"
          style="display: block; width: 65%"
          src="../assets/image/image5.png"
        />
        <p class="p1">案例主图</p>
      </div>
      <div class="box3">
        <p class="t1">{{caseInfo.name}}</p>
        <div class="p2">
          <p class="t2"><span>案例名称：</span><span>{{caseInfo.name}}</span></p>
          <p class="t2">
            <span class="t3">地</span><span>点：</span>
            <span>{{caseInfo.add}}</span>
          </p>
          <p class="t2">
            <span>动工时间：</span><span>{{caseInfo.time}}</span>
          </p>
          <p class="t4">
            <span>其他信息：</span
            ><span
              >{{caseInfo.orther}}</span
            >
          </p>
        </div>
      </div>
      <div class="box4">
        <p class="t5">案例概述</p>
		<p class="t6" v-html="caseInfo.description"></p>
      </div>
      <div class="box5">
        <p>案例小图</p>
        <div class="pics">
          <div class="pic" v-for="(item, index) in caseInfo.imgList">
            <img
              class="img2"
              style="display: block"
			  :src="item"
            />
          </div>
        </div>
      </div>
      <div class="boxright">
        <div class="right1">更多案例</div>
        <div>
          <div class="rightpics">
            <div class="pic">
              <img
                class="img3"
                style="display: block"
                src="../assets/image/newsCenter/image2.jpeg"
              />
              <div class="i3">
                <p class="a1">案例名称</p>
                <p class="a2">案例名称</p>
              </div>
            </div>
            <div class="pic">
              <img
                class="img3"
                style="display: block"
                src="../assets/image/newsCenter/image2.jpeg"
              />
              <div class="i3">
                <p class="a1">案例名称</p>
                <p class="a2">案例名称</p>
              </div>
            </div>
            <div class="pic">
              <img
                class="img3"
                style="display: block"
                src="../assets/image/newsCenter/image2.jpeg"
              />
              <div class="i3">
                <p class="a1">案例名称</p>
                <p class="a2">案例名称</p>
              </div>
            </div>
            <div class="pic">
              <img
                class="img3"
                style="display: block"
                src="../assets/image/newsCenter/image2.jpeg"
              />
              <div class="i3">
                <p class="a1">案例名称</p>
                <p class="a2">案例名称</p>
              </div>
            </div>
            <div class="pic">
              <img
                class="img3"
                style="display: block"
                src="../assets/image/newsCenter/image2.jpeg"
              />
              <div class="i3">
                <p class="a1">案例名称</p>
                <p class="a2">案例名称</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
import caseInfoList from "../../public/caseInfo.json";
export default {
	created() {
	  this.caseId = this.$route.query.caseId;
	  this.getCaseInfo();
	},
	data() {
	  return {
	   caseList: caseInfoList,
	  };
	},
  methods: {
    jump(url) {
      this.$router.push(url);
    },
	getCaseInfo(){
		this.caseInfo = this.caseList[this.caseId];
	}
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.big {
  position: relative;
}
.boxright {
  position: absolute;
  width: 20%;
  right: 64px;
  height: auto;
  top: 165px;
  .right1 {
    font-family: "SourceHanSansCN-Medium";
    text-align: center;
    color: #fff;
    background-color: #d0ac32;
    margin-left: 35px;
    font-size: 22px;
    width: 138px;
    border-radius: 5px;
    height: 30px;
    margin-bottom: 16px;
  }
  .pics {
    display: flex;
    width: 83%;
  }
  .pic {
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    .i3 {
      display: none;
      text-align: right;
      right: 20px;
      width: 100px;
      height: 100px;
      -webkit-animation: mymove 1.5s infinite;
      animation: mymove 1.5s infinite;
      animation-iteration-count: 1;
      -webkit-animation-iteration-count: 1;
      position: absolute;
      top: 47px;
      font-family: "SourceHanSansCN-Medium";
      color: #fff;
      .a1 {
        font-size: 25px;
      }
      .a2 {
        font-size: 16px;
      }
    }
  }
  .img3 {
    height: 110px;
    width: 225px;
  }
}
.pic:hover {
  .i3 {
    display: block;
    right: 90px;
  }
}
@-webkit-keyframes mymove {
  0% {
    right: 20px;
  }
}
.box5 {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  p {
    font-family: "SourceHanSansCN-Medium";
    text-align: left;
    margin-left: 144px;
    margin-top: 14px;
    color: #333;
    font-size: 28px;
  }
  .pics {
    margin-left: 138px;
    margin-top: 35px;
    display: flex;
    width: 83%;
  }
  .pic {
    width: 460px;
    display: flex;
  }
  .img2 {
    height: 110px;
    width: 225px;
  }
}
.box4 {
  font-family: "SourceHanSansCN-Medium";
  text-align: left;
  margin-left: 144px;
  margin-top: 38px;
  color: #333;
  width: 926px;
  .t5 {
    font-size: 28px;
  }
  .t6 {
    font-size: 19px;
    margin-left: 9px;
    margin-top: 33px;
    font-size: 19px;
    text-align: justify;
  }
  .t7 {
    font-size: 19px;
    margin-left: 9px;
    margin-top: 15px;
    font-size: 19px;
    text-align: justify;
  }
}
.box1 {
  width: 100%;
  height: auto;
  margin-top: 91px;
  margin-left: 80px;
  position: relative;
}
.p1 {
  position: absolute;
  font-family: "SourceHanSansCN-Medium";
  text-align: left;
  font-size: 20px;
  color: #fff;
  bottom: 42px;
  font-size: 60px;
  left: 86px;
}
.box3 {
  text-align: left;
  font-family: "SourceHanSansCN-Medium";
}
.t1 {
  font-size: 49px;
  margin-top: 6px;
  margin-left: 145px;
  color: #333;
}
.p2 {
  font-size: 20px;
  color: #333;
  margin-top: 30px;
  margin-left: 150px;
  .t4 {
    text-align: right;
    width: 500px;
  }
}
.t3 {
  margin-right: 41px;
}
.img1 {
  width: 65%;
}
.options {
  position: absolute;
  top: 122px;
  left: 144px;
  width: 500px;
}
.op1 {
  position: absolute;
  left: 26px;
  font-size: 19px;
  top: -7px;
  color: #333;
  font-family: "SourceHanSansCN-Medium";
  cursor: pointer;
}
.icon2 {
  position: absolute;
  left: 131px;
  top: 3px;
}
.icon3 {
  position: absolute;
  left: 265px;
  top: 3px;
}
.op2 {
  position: absolute;
  left: 159px;
  font-size: 19px;
  top: -7px;
  color: #333;
  font-family: "SourceHanSansCN-Medium";
}
.op3 {
  position: absolute;
  left: 294px;
  font-size: 19px;
  top: -7px;
  color: #333;
  font-family: "SourceHanSansCN-Medium";
}
@media (max-width: 500px) {
  .options {
    margin-top: 100px;
    margin-left: -80px;
    width: 1000px;
    .op1 {
      margin-left: 50px;
      font-size: 30px;
      margin-top: 8px;
    }
    .icon2 {
      position: absolute;
      left: 250px;
    }
    .op2 {
      margin-left: 180px;
      font-size: 30px;
      margin-top: 8px;
    }
    .icon3 {
      position: absolute;
      left: 520px;
    }
    .op3 {
      margin-left: 310px;
      font-size: 30px;
      margin-top: 8px;
    }
  }
  .boxright {
    top: 265px;
  }
  .box4 {
    .t6 {
      font-size: 18px;
    }
    .t7 {
      font-size: 18px;
    }
  }
}
</style>